import {useState} from "react";
import Form from "react-bootstrap/Form";
import {Controller, useForm} from "react-hook-form";
import {Form as rForm, Radio, RadioGroup} from "rsuite";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";

import {yupResolver} from "@hookform/resolvers/yup";

import {Breadcrumb, Button} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";
import {createUser} from "../../services/user";
import {InputValidationError} from "../../libs/error";
import {useNotification} from "../../hooks/useNotification";
import {userInputSchema} from "./schema";


export function UserCreatePage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError
  } = useForm({
    resolver: yupResolver(userInputSchema)
  });

  const addUser = async (data) => {
    try {
      setIsLoading(true);
      await createUser(data);
      notifySuccess({
        title: "User created successfully"
      }, -1);

    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message
      }, "/error");
    } finally {
      setIsLoading(false);
    }
  };

  const LoadingComponent = isLoading ? <CircleLoader /> : null;
  const {t} = useLang();

  return (
    <Layout>
      {LoadingComponent}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/user`}> {t("users_page_title")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("users_create_crateUser")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("users_page_title")} />
        </div>
      </header>

      <div className="form_design">
        <h2 className="text-dark text-decoration-underline"> {t("users_create_crateUser")} </h2>
        <Form onSubmit={handleSubmit(addUser)} className="d-flex flex-column">
          {/* Name */}
          <Form.Group controlId="userName">
            <Form.Label className="form_label mark-required">{t("users_create_name")}</Form.Label>
            <Form.Control
              {...register("name")}
              type="text"
              placeholder={t("users_create_name_plhd")}
            />
            {errors?.name?.message && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          {/* Email */}
          <Form.Group controlId="userName">
            <Form.Label className="form_label mark-required">{t("users_create_email")}</Form.Label>
            <Form.Control
              {...register("email")}
              type="email"
              placeholder={t("users_create_email_plhd")}
            />
            {errors?.email?.message && (
              <Form.Text className="text-danger">
                {errors.email.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Phone */}
          <Form.Group controlId="userPhone">
            <Form.Label className="form_label mark-required">{t("users_create_phone")}</Form.Label>
            <Form.Control
              {...register("phone")}
              type="tel"
              placeholder={t("users_create_phone__plhd")}
            />
            {errors?.phone?.message && (
              <Form.Text className="text-danger">
                {errors.phone.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Gender */}
          <rForm.Group controlId="Gender">
            <Form.Label className="form_label mark-required">{t("users_create_gender")}</Form.Label>
            <Controller
              name="gender"
              control={control}
              render={({field}) => (
                <RadioGroup {...field} name="Gender" inline>
                  <Radio value="Male">{t("users_create_genderMale")}</Radio>
                  <Radio value="Female">{t("users_create_genderFemale")}</Radio>
                  <Radio value="Other">{t("users_create_genderOther")}</Radio>
                </RadioGroup>
              )}
            />
            {errors?.gender?.message && (
              <Form.Text className="text-danger">
                {errors.gender.message}
              </Form.Text>
            )}
          </rForm.Group>

          <div class="mt-3 d-flex gap-2">
            <Button className="px-3" type="submit" variant="primary">
              {t("users_create_save")}
            </Button>
            <Link className="btn btn-danger px-3" to="/user">
              {t("users_create_cancel")}
            </Link>
          </div>
        </Form>
      </div>
    </Layout>
  );
}
