import React,{useState,useEffect } from 'react';
import './role.css';
import { Link,useNavigate } from 'react-router-dom';
import {Button, Modal, Toast, Badge, Breadcrumb} from "react-bootstrap";
import http from "../../http"
// import {GrView} from "react-icons/gr";
// import {AiTwotoneEdit} from "react-icons/ai";
// import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import ComponentGuard from '../../helpers/ComponentGuard';
import {useLang} from "../../hooks";

const Role = () => {

    const [show, setShow] = useState(false);
    const [id, setId] = useState('');
    const [users, setUsers] = useState([]);
    const [getDeleteUser, setDeleteUser] = useState(false);
    const [search, setSearch] = useState("");
    const [filterUsers, setFilterUsers] = useState([]);

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
    const [getPermisson, setPermisson] = useState(false);
    const cookies = new Cookies();
    const {t} = useLang();

  

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });

    function permission(){
        http.get(`/permission/sync-route-to-permission`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        }}
        ).then((response)=>{
            if(response.status == 200){
                setPermisson(true)              
            }  
            return
        }).then(()=>{
            setTimeout(()=>{
                setPermisson(false)
            }
            ,2000)  
        })

    }
    useEffect(()=>{
        fetchAllUsers(getCurrentPage);
    },[getDeleteUser]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http.get(`/role/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             }}
             ).then((response)=>{
            setTotalRows(response.data.data.total);
            setFilterUsers(response.data.data.data);
        })
        
        // })
        // fetchAllMenus2()
    },[search]);


    // +++++++++++++++++++
    const fetchAllUsers = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(`/role/admin/all?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             }});
        setFilterUsers(response.data.data.data)
		setUsers(response.data.data.data);
		setTotalRows(response.data.data.total);
		setLoading(false);
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

		setLoading(true);
		const response = await http.get(`/role/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             }});
        
		setUsers(response.data.data.data);
        setFilterUsers(response.data.data.data)
		setPerPage(perPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAllUsers(1); // fetch page 1 of users
		
	}, []);

    // +++++++++++++++++++


    console.log(users)


    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: t("acl_roleList_col_rolName"),
            selector: row => row.name,
            sortable:true
        },
        {
            name: t("acl_roleList_col_action"),
            cell: (row) => 
            (
                <>
                    <ComponentGuard needsPermission={['role.givePermissionToRole']}>
                        {row.name != "SuperAdmin" &&
                            <Link to={"permission/"+row.id}>
                                <Button className="action__btn" variant="primary">
                                    {t("acl_roleList_col_givePermission")}
                                </Button>
                            </Link>
                        }
                    </ComponentGuard>

                    <ComponentGuard needsPermission={['role.revokePermissionFromRole']}>
                        {row.permissions.length > 0 &&
                        <Link to={"revoke-permission/"+row.id}>
                            <Button className="action__btn" variant="info">
                                {t("acl_roleList_col_revokePermission")}
                            </Button>
                        </Link>
                        }
                    </ComponentGuard>
                    
                    <ComponentGuard needsPermission={['role.assignRoleToUser']}>
                        <Link to={"assign-role/"+row.id}>
                            <Button className="action__btn" variant="info">
                                {t("acl_roleList_col_assignUser")}
                            </Button>
                        </Link>
                    </ComponentGuard>
                    {/* <Button className="action__btn" onClick={() =>handleShow(row.id)} variant="danger">
                        <MdDelete/>
                    </Button> */}
                </>
            ),            
        }
    ]
    return (
        <>
            <Layout>

                {
                    (getPermisson == true) ?
                        (
                            <Toast bg='success' style={{position: 'fixed', top: '30px', right: '0', zIndex: '111111'}}>
                                <Toast.Body>Succesfully Sync</Toast.Body>
                            </Toast>
                        )
                        : null
                }
                <header>
                    <div className="d-flex justify-content-between">
                        <div>
                            <Breadcrumb>
                                <Breadcrumb.Item active>
                                    {t("acl_roleList_roleList")}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </header>

                <ComponentGuard needsPermission={['role.create']}>
                    <Link to="create" style={{paddingRight: "5px"}}>
                        <Button className="create_btn btn btn-primary"
                                variant="primary">{t("acl_roleList_addRole")}</Button>
                    </Link>
                </ComponentGuard>

                {/* <ComponentGuard needsPermission={['role.userRoles']}>
                <Link to="user-role-list" style={{paddingRight:"5px"}}>
                    <Button className="create_btn btn btn-primary" variant="primary">User Assign Role List</Button>
                </Link>
            </ComponentGuard> */}

                <ComponentGuard needsPermission={['permission.addRouteAsPermission']}>
                    {/* <Link to="user-role-list"> */}
                    <Button className="create_btn btn btn-primary" onClick={permission}
                            variant="primary">{t("acl_roleList_syncPermission")}</Button>
                    {/* </Link> */}
                </ComponentGuard>

                <DataTable
                    title={t("acl_roleList_roleList")}
                    columns={columns}
                    data={filterUsers}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationComponentOptions={{noRowsPerPage: true}}
                    paginationTotalRows={totalRows}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    fixedHeader
                    fixedHeaderScrollHeight="800px"
                    highlightOnHover
                    subHeader
                    subHeaderComponent={
                        <input
                            type="text"
                            placeholder={t("acl_roleList_search")}
                            className="search_btn form-control"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    }
                    subHeaderAlign="right"
                />


                {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Link to="/department">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteDepartment(id)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal> */}

            </Layout>
        </>
    );
}

export default Role;