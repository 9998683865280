import {Badge, Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsGlobe, BsPencilSquare} from "react-icons/bs";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {FloatingButton} from "../../components";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useEventList, useLang} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {IoExtensionPuzzleOutline} from "react-icons/io5";
import moment from "moment";

export function EventListPage() {
  const {t} = useLang();
  const {notifyError, notifySuccess} = useNotification();

  const {
    list,
    pagination,
    changePagination,
    error,
    isLoading,
    deleteItem
  } = useEventList();


  const navigate = useNavigate();
  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await deleteItem(id);
      notifySuccess({
        title: "Post removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove content!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("events_manage_col_title"),
      selector: (row) => (
        <div className="text-truncate" style={{width: "200px"}}>
          {row.title}
        </div>
      ),
      sortable: true
    },
    {
      name: t("events_manage_col_eventState"),
      selector: (row) => {
        let bg = "primary";
        switch (row.type) {
          case "Upcoming":
            bg = "success";
            break;
          case "Ongoing"  :
            bg = "primary";
            break;
          default:
            bg = "secondary";
            break;
        }
        return <Badge bg={bg}>{row.type}</Badge>;
      },
      sortable: true
    },
    {
      name: t("events_manage_col_category"),
      selector: (row) => row.event_category?.name ?? "Not Available",
      sortable: true
    },
    {
      name: t("events_manage_col_Duration"),
      selector: (row) => {
        return moment.duration(moment(row.start_date).diff(moment(row.end_date))).humanize();
      },
      sortable: true
    },
    {
      name: t("events_manage_col_time"),
      width: "300px",
      selector: (row) =>
        `${moment(row.start_date).format("DD-MM-YY (hh:mm a)")} - ${moment(row.end_date).format("DD-MM-YY (hh:mm a)")}`,
      sortable: true
    },
    {
      name: t("events_manage_col_status"),
      selector: (row) => <Badge
        bg={row.status ? "success" : "danger"}>{row.status ? "Active" : "Deactive"}</Badge>,
      sortable: true
    },
    {
      name: t("events_manage_col_action"),
      width: "300px",
      selector: (row) => (
        <div className="d-flex gap-2">
          <ComponentGuard needsPermission={[Permission.EventUpdate]}>
            <Link to={`/events/${row.id}/edit`} className="btn btn-secondary">
              <BsPencilSquare />
            </Link>
          </ComponentGuard>
          <ComponentGuard needsPermission={[Permission.EventDelete]}>
            <Button variant="danger" onClick={() => handleDelete(row.id)}>
              <MdDeleteOutline />
            </Button>
          </ComponentGuard>
          <ComponentGuard needsPermission={[Permission.EventLanguagesList]}>
            <Link to={`/langs/event/${row.id}`} className="btn btn-info">
              <BsGlobe />
            </Link>
          </ComponentGuard>
        </div>
      )
    }
  ];

  return (
    <>
      <Layout>
        {isLoading && <CircleLoader />}
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("events_manage_events")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="post-list" className="mb-5 pb-5">
          <h2>{t("events_manage_events")}</h2>
          <DataTable
            columns={columns}
            data={list.events}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={list.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.EventStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/events/new");
          }}
          icon={<IoExtensionPuzzleOutline />}
          name={t("events_create_createNewEvent")}
        />
      </ComponentGuard>
    </>
  );
}

export default EventListPage;
